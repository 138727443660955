import React from "react";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import {
    AustracReportItem,
    AustracReportPromptsItem,
    BankableOverviewReportObject,
    GroupReportDynamicDataItem,
    GroupReportItem,
    ReportDynamicCSVDataItem,
    ReportDynamicDataItem,
    ReportItem,
    ShiftReportMetricsItem,
} from "@interfaces";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Parser } from "json2csv";
import { Group } from "../../Types/Group";
import { TableWrapCell } from "./Components/CashupReportStyled";

export const filterVenueData = (venueList: any[]) => {
    const venueOptions =
        venueList?.map(({ venue_id, name, group_id }) => ({
            id: venue_id,
            value: venue_id,
            label: name,
            type: "venue",
            group_id: group_id,
        })) ?? [];
    return venueOptions;
};

export const updateGroupsData = (groupList: Group[]) => {
    const groupOptions =
        groupList?.map(({ group_id, client_group_name }) => ({
            id: group_id,
            value: group_id,
            label: client_group_name,
            type: "group",
        })) ?? [];
    return groupOptions;
};

export const getAllUniqueKeys = (arrayList: any) => {
    const allKeys = Array.from(
        new Set(
            arrayList
                .flatMap((item: any) => Object.keys(item))
                .filter((key: any) => key !== "date" && key !== "venue")
        )
    );
    return allKeys;
};

export const sortDataBasedOnKeys = (dataArray: any) => {
    return dataArray.map((obj: any) => {
        const sortedObj: Record<string, number | string> = {};
        Object.keys(obj)
            .sort()
            .forEach((key) => {
                if (Array.isArray(obj[key])) {
                    sortedObj[key] = sortDataBasedOnKeys(obj[key]);
                } else {
                    sortedObj[key] = obj[key];
                }
            });
        return sortedObj;
    });
};

export const columnGenerator = (
    allKeys: string[],
    rowHeader: boolean,
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: rowHeader ? "Date" : "",
            dataIndex: "date",
            key: "date",
            width: 100,
            sorter: (a: any, b: any) =>
                dayjs(a.date, "DD/MM/YYYY").unix() -
                dayjs(b.date, "DD/MM/YYYY").unix(),
            sortOrder: sortedColumn === "date" ? sortDirection : null,
        },
        ...allKeys.map((key: string) => ({
            title: key,
            dataIndex: key,
            key,
            render: (value: number) => (
                <>
                    {key !== "Return to House"
                        ? `$${formatNumberWithCommas(Number(value.toFixed(2)))}`
                        : `${Number(value).toFixed(2)}%`}
                </>
            ),
            sorter: (a: any, b: any) => a[key] - b[key],
            sortOrder: sortedColumn === key ? sortDirection : null,
            width: 100,
        })),
    ];
    return columns;
};

export const payoutColumnGenerator = (
    allKeys: string[],
    rowHeader: boolean,
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: rowHeader ? "Date" : "",
            dataIndex: "date",
            key: "date",
            width: 100,
            sorter: (a: any, b: any) =>
                dayjs(a.date, "DD/MM/YYYY").unix() -
                dayjs(b.date, "DD/MM/YYYY").unix(),
            sortOrder: sortedColumn === "date" ? sortDirection : null,
        },
        ...allKeys.map((key: string) => ({
            title: key,
            dataIndex: key,
            key,
            render: (value: number | string) => (
                <TableWrapCell>
                    {key === "Tender Amount"
                        ? `$${formatNumberWithCommas(
                              Number(Number(value).toFixed(2))
                          )}`
                        : value === 0
                        ? null
                        : value}
                </TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                // Handle sorting for both numbers and strings
                if (typeof a[key] === "number" && typeof b[key] === "number") {
                    return a[key] - b[key];
                } else {
                    return String(a[key]).localeCompare(String(b[key]));
                }
            },
            sortOrder: sortedColumn === key ? sortDirection : null,
            width: 100,
        })),
    ];
    return columns;
};

export const bankableColumnGenerator = (
    rowHeader: boolean,
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: rowHeader ? "Date" : "",
            dataIndex: "date",
            key: "date",
            width: 100,
            sorter: (a: any, b: any) =>
                dayjs(a.date, "DD/MM/YYYY").unix() -
                dayjs(b.date, "DD/MM/YYYY").unix(),
            sortOrder: sortedColumn === "date" ? sortDirection : null,
        },
        {
            title: "Till",
            dataIndex: "till",
            key: "till",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (typeof a["till"] === "number" && typeof b["till"] === "number") {
                    return a["till"] - b["till"];
                } else {
                    return String(a["till"]).localeCompare(String(b["till"]));
                }
            },
            sortOrder: sortedColumn === "till" ? sortDirection : null,
        },
        {
            title: "Cash In",
            dataIndex: "cash_in",
            key: "cash_in",
            width: 100,
            render: (value: number) => (
                <TableWrapCell>
                    {value === undefined || value === null || value === 0
                        ? "-"
                        : `$${formatNumberWithCommas(Number(value.toFixed(2)))}`}
                </TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                return a["cash_in"] - b["cash_in"];
            },
            sortOrder: sortedColumn === "cash_in" ? sortDirection : null,
        },
        {
            title: "Cash Out",
            dataIndex: "cash_out",
            key: "cash_out",
            width: 100,
            render: (value: number) => (
                <TableWrapCell>
                    {value === undefined || value === null || value === 0
                        ? "-"
                        : `$${formatNumberWithCommas(Number(value.toFixed(2)))}`}
                </TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                return a["cash_out"] - b["cash_out"];
            },
            sortOrder: sortedColumn === "cash_out" ? sortDirection : null,
        },
    ];
    return columns;
};

export const pettyCashColumnGenerator = (
    rowHeader: boolean,
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: rowHeader ? "Date" : "",
            dataIndex: "date",
            key: "date",
            width: 100,
            sorter: (a: any, b: any) =>
                dayjs(a.date, "DD/MM/YYYY").unix() -
                dayjs(b.date, "DD/MM/YYYY").unix(),
            sortOrder: sortedColumn === "date" ? sortDirection : null,
        },
        {
            title: "GL Code",
            dataIndex: "gl_code",
            key: "gl_code",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["gl_code"] === "number" &&
                    typeof b["gl_code"] === "number"
                ) {
                    return a["gl_code"] - b["gl_code"];
                } else {
                    return String(a["gl_code"]).localeCompare(String(b["gl_code"]));
                }
            },
            sortOrder: sortedColumn === "gl_code" ? sortDirection : null,
        },
        {
            title: "Class",
            dataIndex: "class_name",
            key: "class_name",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["class_name"] === "number" &&
                    typeof b["class_name"] === "number"
                ) {
                    return a["class_name"] - b["class_name"];
                } else {
                    return String(a["class_name"]).localeCompare(
                        String(b["class_name"])
                    );
                }
            },
            sortOrder: sortedColumn === "class_name" ? sortDirection : null,
        },
        {
            title: "Description",
            dataIndex: "petty_cash_description",
            key: "petty_cash_description",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["petty_cash_description"] === "number" &&
                    typeof b["petty_cash_description"] === "number"
                ) {
                    return a["petty_cash_description"] - b["petty_cash_description"];
                } else {
                    return String(a["petty_cash_description"]).localeCompare(
                        String(b["petty_cash_description"])
                    );
                }
            },
            sortOrder:
                sortedColumn === "petty_cash_description" ? sortDirection : null,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            width: 100,
            render: (value: number) => (
                <TableWrapCell>{`$${formatNumberWithCommas(
                    Number(value.toFixed(2))
                )}`}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                return a["amount"] - b["amount"];
            },
            sortOrder: sortedColumn === "amount" ? sortDirection : null,
        },
        {
            title: "GST",
            dataIndex: "gst",
            key: "gst",
            width: 100,
            render: (value: number) => (
                <TableWrapCell>{`$${formatNumberWithCommas(
                    Number(value.toFixed(2))
                )}`}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                return a["gst"] - b["gst"];
            },
            sortOrder: sortedColumn === "gst" ? sortDirection : null,
        },
    ];
    return columns;
};

export const approvalsColumnGenerator = (
    rowHeader: boolean,
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: rowHeader ? "Date" : "",
            dataIndex: "date",
            key: "date",
            width: 100,
            sorter: (a: any, b: any) =>
                dayjs(a.date, "DD/MM/YYYY").unix() -
                dayjs(b.date, "DD/MM/YYYY").unix(),
            sortOrder: sortedColumn === "date" ? sortDirection : null,
        },
        {
            title: "Tab",
            dataIndex: "Tab",
            key: "Tab",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (typeof a["Tab"] === "number" && typeof b["Tab"] === "number") {
                    return a["Tab"] - b["Tab"];
                } else {
                    return String(a["Tab"]).localeCompare(String(b["Tab"]));
                }
            },
            sortOrder: sortedColumn === "Tab" ? sortDirection : null,
        },
        {
            title: "Location",
            dataIndex: "Location",
            key: "Location",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["Location"] === "number" &&
                    typeof b["Location"] === "number"
                ) {
                    return a["Location"] - b["Location"];
                } else {
                    return String(a["Location"]).localeCompare(
                        String(b["Location"])
                    );
                }
            },
            sortOrder: sortedColumn === "Location" ? sortDirection : null,
        },
        {
            title: "Entry Variance",
            dataIndex: "entry_variance",
            key: "entry_variance",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{`$${formatNumberWithCommas(
                    Number(Number(value).toFixed(2))
                )}`}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                return a["entry_variance"] - b["entry_variance"];
            },
            sortOrder: sortedColumn === "entry_variance" ? sortDirection : null,
        },
        {
            title: "Cash Variance",
            dataIndex: "cash_variance",
            key: "cash_variance",
            width: 100,
            render: (value: number) => (
                <TableWrapCell>{`$${formatNumberWithCommas(
                    Number(Number(value).toFixed(2))
                )}`}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                return a["cash_variance"] - b["cash_variance"];
            },
            sortOrder: sortedColumn === "cash_variance" ? sortDirection : null,
        },
        {
            title: "Comments",
            dataIndex: "Comments",
            key: "Comments",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["Comments"] === "number" &&
                    typeof b["Comments"] === "number"
                ) {
                    return a["Comments"] - b["Comments"];
                } else {
                    return String(a["Comments"]).localeCompare(
                        String(b["Comments"])
                    );
                }
            },
            sortOrder: sortedColumn === "Comments" ? sortDirection : null,
        },
        {
            title: "Accepted By",
            dataIndex: "accepted_by",
            key: "accepted_by",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["accepted_by"] === "number" &&
                    typeof b["accepted_by"] === "number"
                ) {
                    return a["accepted_by"] - b["accepted_by"];
                } else {
                    return String(a["accepted_by"]).localeCompare(
                        String(b["accepted_by"])
                    );
                }
            },
            sortOrder: sortedColumn === "accepted_by" ? sortDirection : null,
        },
        {
            title: "Submitted By",
            dataIndex: "submitted_by",
            key: "submitted_by",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["submitted_by"] === "number" &&
                    typeof b["submitted_by"] === "number"
                ) {
                    return a["submitted_by"] - b["submitted_by"];
                } else {
                    return String(a["submitted_by"]).localeCompare(
                        String(b["submitted_by"])
                    );
                }
            },
            sortOrder: sortedColumn === "submitted_by" ? sortDirection : null,
        },
        {
            title: "Approved By",
            dataIndex: "approved_by",
            key: "approved_by",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                if (
                    typeof a["approved_by"] === "number" &&
                    typeof b["approved_by"] === "number"
                ) {
                    return a["approved_by"] - b["approved_by"];
                } else {
                    return String(a["approved_by"]).localeCompare(
                        String(b["approved_by"])
                    );
                }
            },
            sortOrder: sortedColumn === "approved_by" ? sortDirection : null,
        },
        {
            title: "Approved Date",
            dataIndex: "approved_date",
            key: "approved_date",
            width: 100,
            render: (value: number | string) => (
                <TableWrapCell>{value === 0 ? null : value}</TableWrapCell>
            ),
            sorter: (a: any, b: any) => {
                const aValue = a.approved_date
                    ? dayjs(a.approved_date, "DD/MM/YYYY").unix()
                    : 0;
                const bValue = b.approved_date
                    ? dayjs(b.approved_date, "DD/MM/YYYY").unix()
                    : 0;
                return aValue - bValue;
            },
            sortOrder: sortedColumn === "approved_date" ? sortDirection : null,
        },
    ];
    return columns;
};

export const totalColumnGenerator = (totalRowValues: (string | number)[] = []) => {
    const columns: ColumnsType<any> = [
        {
            title: "Total",
            dataIndex: "total",
            width: 100,
        },
        ...totalRowValues.map((key: string | number, index: number) => ({
            title: `$${Number(key).toFixed(2)}`,
            dataIndex: `column_${index}`,
            width: 100,
        })),
    ];
    return columns;
};

export const shiftPromptsColumnGenerator = (
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            width: 200,
            sorter: (a: any, b: any) =>
                dayjs(a.date, "DD/MM/YYYY").unix() -
                dayjs(b.date, "DD/MM/YYYY").unix(),
            sortOrder: sortedColumn === "date" ? sortDirection : null,
        },
        {
            title: "Answer",
            dataIndex: "answer",
            key: "answer",
            render: (value: any) => (
                <>{value === "" ? "No user response entered" : value}</>
            ),
            sorter: (a: any, b: any) => {
                const valA =
                    a.answer === "" ? "No user response entered" : String(a.answer);
                const valB =
                    b.answer === "" ? "No user response entered" : String(b.answer);
                return valA.localeCompare(valB);
            },
            sortOrder: sortedColumn === "answer" ? sortDirection : null,
        },
    ];
    return columns;
};

export const austracPromptsColumnGenerator = (
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: "Investigation Question",
            dataIndex: "question_text",
            key: "question_text",
            width: "40%",
        },
        {
            title: "Comments",
            dataIndex: "comments",
            key: "comments",
            width: "22%",
            sorter: (a: AustracReportPromptsItem, b: AustracReportPromptsItem) =>
                String(a["comments"]).localeCompare(String(b["comments"])),
            sortOrder: sortedColumn === "comments" ? sortDirection : null,
        },
        {
            title: "Completed",
            key: "status",
            dataIndex: "status",
            width: "8%",
            render: (value: number) => <>{value === 2 ? "Yes" : "No"}</>,
            sorter: (a: AustracReportPromptsItem, b: AustracReportPromptsItem) =>
                a.status - b.status,
            sortOrder: sortedColumn === "status" ? sortDirection : null,
        },
        {
            title: "Completed By",
            dataIndex: "completed_by",
            key: "completed_by",
            width: "10%",
            sorter: (a: AustracReportPromptsItem, b: AustracReportPromptsItem) =>
                String(a["completed_by"]).localeCompare(String(b["completed_by"])),
            sortOrder: sortedColumn === "completed_by" ? sortDirection : null,
        },
        {
            title: "Completed Date",
            dataIndex: "completed_at",
            key: "completed_at",
            width: "10%",
            render: (value: string) => (
                <>{value ? formatUTCToLocalTime(value) : ""}</>
            ),
            sorter: (a: AustracReportPromptsItem, b: AustracReportPromptsItem) => {
                const aValue = a.completed_at ? dayjs(a.completed_at).unix() : 0;
                const bValue = b.completed_at ? dayjs(b.completed_at).unix() : 0;
                return aValue - bValue;
            },
            sortOrder: sortedColumn === "completed_at" ? sortDirection : null,
        },
        {
            title: "Approved By",
            dataIndex: "approved_by",
            key: "approved_by",
            width: "10%",
            sorter: (a: AustracReportPromptsItem, b: AustracReportPromptsItem) =>
                String(a["approved_by"]).localeCompare(String(b["approved_by"])),
            sortOrder: sortedColumn === "approved_by" ? sortDirection : null,
        },
    ];
    return columns;
};

export const shiftMetricsColumnGenerator = (
    allKeys: string[],
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        ...allKeys.map((key: string) => ({
            title: key,
            dataIndex: key,
            key,
            //align: "center" as const,
            render: (value: string | number, record: any) => (
                <>
                    {typeof value === "string"
                        ? value
                        : record.Metrics !== "Return To House"
                        ? `$${formatNumberWithCommas(
                              Number(Number(value).toFixed(2))
                          )}`
                        : `${Number(value).toFixed(2)}%`}
                </>
            ),
            sorter: (a: any, b: any) => {
                // Handle sorting for both numbers and strings
                if (typeof a[key] === "number" && typeof b[key] === "number") {
                    return a[key] - b[key];
                } else {
                    return String(a[key]).localeCompare(String(b[key]));
                }
            },
            sortOrder: sortedColumn === key ? sortDirection : null,
        })),
    ];
    return columns;
};

export const posGroupColumnGenerator = (
    allKeys: string[],
    rowHeader: boolean,
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: rowHeader ? "Venue" : "",
            dataIndex: "venue",
            key: "venue",
            width: 150,
            sorter: (a: any, b: any) => a.venue.localeCompare(b.venue),
            sortOrder: sortedColumn === "venue" ? sortDirection : null,
        },
        ...allKeys.map((key: string) => ({
            title: key,
            dataIndex: key,
            key,
            render: (value: number) => (
                <>
                    {value !== undefined
                        ? `$${formatNumberWithCommas(Number(value.toFixed(2)))}`
                        : "-"}
                </>
            ),
            sorter: (a: any, b: any) => (a[key] || 0) - (b[key] || 0),
            sortOrder: sortedColumn === key ? sortDirection : null,
            width: 150,
        })),
    ];
    return columns;
};

export const gamingGroupColumnGenerator = (
    allKeys: string[],
    rowHeader: boolean,
    sortedColumn: string | null,
    sortDirection: SortOrder
) => {
    const columns: ColumnsType<any> = [
        {
            title: rowHeader ? "Venue" : "",
            dataIndex: "venue",
            key: "venue",
            width: 100,
            sorter: (a: any, b: any) => a.venue.localeCompare(b.venue),
            sortOrder: sortedColumn === "venue" ? sortDirection : null,
        },
        ...allKeys.map((key: string) => ({
            title: key,
            dataIndex: key,
            key,
            render: (value: number) => (
                <>
                    {key !== "Return to House"
                        ? `$${formatNumberWithCommas(Number(value.toFixed(2)))}`
                        : `${Number(value).toFixed(2)}%`}
                </>
            ),
            sorter: (a: any, b: any) => (a[key] || 0) - (b[key] || 0),
            sortOrder: sortedColumn === key ? sortDirection : null,
            width: 100,
        })),
    ];
    return columns;
};

export const prepareTotalRow = (
    allKeys: string[],
    totals: Record<string, number>
) => {
    const sortedObject: Record<string, number> = {};
    allKeys.forEach((key) => {
        sortedObject[key] = totals[key];
    });
    return sortedObject;
};

export const totalsForTable = (arrayList: any) => {
    const totals: Record<string, number> = {};
    arrayList.forEach((item: any) => {
        Object.keys(item).forEach((key) => {
            if (key !== "date") {
                totals[key] = (totals[key] || 0) + (item[key] || 0);
            }
        });
    });
    //limit to 2 decimals
    Object.keys(totals).forEach((key) => {
        totals[key] = parseFloat(totals[key].toFixed(2));
    });
    return totals;
};

export const totalsForAllLocations = (dataList: any) => {
    const totals: Record<string, number> = {};
    //Iterate over each data item in the location
    dataList.forEach((location: any) => {
        location.data.forEach((item: any) => {
            Object.keys(item).forEach((key) => {
                if (key !== "date" && key !== "venue") {
                    totals[key] = (totals[key] || 0) + (item[key] || 0);
                }
            });
        });
    });
    //limit to 2 decimals
    Object.keys(totals).forEach((key) => {
        totals[key] = parseFloat(totals[key].toFixed(2));
    });
    return totals;
};

export const prepareBarGraphDataForPettyCash = (
    dataList: any[],
    xLabels: string[]
) => {
    // Group data by GL Code
    const groupedData: Record<
        string,
        {
            date: string;
            amount: number;
        }[]
    > = dataList.reduce((acc, item) => {
        if (!acc[item["gl_code"]]) {
            acc[item["gl_code"]] = xLabels.map((date) => ({
                date,
                amount: 0,
            }));
        }
        const dateIndex = acc[item["gl_code"]].findIndex(
            (entry: { date: string; amount: number }) => entry.date === item.date
        );
        //add amounts when there are multiple entries for the same date
        if (dateIndex !== -1) {
            acc[item["gl_code"]][dateIndex].amount += Number(item.amount.toFixed(2));
        }
        return acc;
    }, {});

    // Prepare series data for bar chart
    const series: {
        name: string;
        data: number[];
    }[] = Object.keys(groupedData).map((glCode) => ({
        name: glCode,
        data: groupedData[glCode].map((entry: { date: string; amount: number }) =>
            Number(entry.amount.toFixed(2))
        ),
    }));

    return {
        graphData: series,
        labels: xLabels.map((item) => dayjs(item, "DD/MM/YYYY").format("DD MMM")),
    };
};

export const prepareBarGraphData = (dataList: any) => {
    const totalsArray: any[] = [];
    dataList.forEach((location: any) => {
        const totals: Record<string, string | Record<string, number>> = {
            name: location.parent_location_name
                ? location.parent_location_name
                : "Gaming",
            data: totalsForTable(location.data),
        };
        totalsArray.push(totals);
    });
    //transform data
    const barData = transformBarGraphData(totalsArray);
    return barData;
};

const transformBarGraphData = (inputData: any) => {
    const labels: string[] = [];
    const graphData: Record<string, any[]> = {};

    inputData.forEach((item: any) => {
        labels.push(item.name);

        Object.entries(item.data).forEach(([key, value]) => {
            if (!graphData[key]) {
                graphData[key] = [];
            }
            graphData[key].push(value);
        });
    });

    const output: any = {
        labels,
        graphData: Object.entries(graphData).map(([name, data]) => ({
            name,
            data,
        })),
    };
    return output;
};

export const totalsForPayouts = (dataList: any) => {
    const totals: Record<string, number> = {};
    //Iterate over each data item in the location
    dataList.forEach((location: any) => {
        location.data.forEach((item: any) => {
            Object.keys(item).forEach((key) => {
                if (key !== "date") {
                    if (typeof item[key] === "string") {
                        totals[key] = 0;
                    } else {
                        totals[key] = (totals[key] || 0) + (item[key] || 0);
                    }
                }
            });
        });
    });
    //limit to 2 decimals
    Object.keys(totals).forEach((key) => {
        totals[key] = parseFloat(totals[key].toFixed(2));
    });
    return totals;
};

export const prepareShiftGraphData = (
    inputShiftData: any,
    allKeys: string[],
    chartLabal: string
) => {
    const chartData: {
        name: string;
        data: number[];
    }[] = [];

    inputShiftData.forEach((item: any) => {
        if (
            item[allKeys[0]] !== "Return To House" &&
            item[allKeys[0]] !== "Actual v Metered"
        ) {
            chartData.push({
                name: item[allKeys[0]],
                data: [Number(item[allKeys[1]].toFixed(2))],
            });
        }
    });

    const output = {
        labels: [chartLabal],
        graphData: chartData,
    };
    return output;
};

export const calculateTotalGamingReturnToHouse = (
    totalsData: Record<string, number>
) => {
    const returnToHouse = (
        (totalsData["Metered Net Profit"] / totalsData["Turnover"]) *
        100
    ).toFixed(2);
    //update return to house
    const updatedTotals = { ...totalsData };
    updatedTotals["Return to House"] = Number(returnToHouse);
    return updatedTotals;
};

export const calculateGamingActualNetProfitPercent = (
    totalsData: Record<string, string | number>
) => {
    const actualNetProfit = Number(totalsData["Actual Net Profit"]);
    const cashCount = Number(totalsData["Clearance"]);

    // Return default value
    if (isNaN(actualNetProfit) || isNaN(cashCount) || cashCount === 0) {
        return [0];
    }
    const actualNetProfitPercent = ((actualNetProfit / cashCount) * 100).toFixed(2);
    return [Number(actualNetProfitPercent)];
};

export const prepareLineChartData = (
    reportData: ReportItem[],
    columnName: string,
    dateRangeList: string[]
) => {
    const graphData: {
        name: string;
        data: number[];
    }[] = reportData.map((item) => {
        return {
            name: item.parent_location_name || "Gaming",
            data: prepareLineChartDataForEachLocation(
                item,
                columnName,
                dateRangeList
            ),
        };
    });
    return graphData;
};

export const prepareLineChartDataForPayouts = (
    reportData: ReportItem[],
    columnName: string,
    dateRangeList: string[]
) => {
    //unique keys to plot lines for Localtion+Ternder combination
    const uniqueTenderType = Array.from(
        new Set(
            reportData[0].data.map(({ Location, Tender }) => `${Location}-${Tender}`)
        )
    );

    const graphData: {
        name: string;
        data: number[];
    }[] = uniqueTenderType.map((item) => {
        return {
            name: item,
            data: prepareLineChartDataForPayoutType(
                reportData[0],
                columnName,
                dateRangeList,
                item
            ),
        };
    });
    return graphData;
};

const prepareLineChartDataForPayoutType = (
    reportItemData: ReportItem,
    columnName: string,
    dateRangeList: string[],
    tenderType: string
) => {
    const graphData: number[] = [];
    const payoutMap = new Map();
    let aggregatedPayoutData: ReportDynamicDataItem[] = [];

    //aggregate Tender Amount from unique payout type
    reportItemData.data.forEach((item) => {
        const key = `${item.date}-${item.Location}-${item.Tender}`;
        if (!payoutMap.has(key)) {
            payoutMap.set(key, { ...item });
        } else {
            const existing = payoutMap.get(key);
            existing["Tender Amount"] += item["Tender Amount"];
        }
    });
    aggregatedPayoutData = Array.from(payoutMap.values());
    // prepare data for each tender type
    dateRangeList.forEach((dateItem) => {
        const foundDateItem = aggregatedPayoutData.find(
            (obj) =>
                obj.date === dateItem &&
                `${obj["Location"]}-${obj["Tender"]}` === tenderType
        );
        //if item entry is found then add the value
        if (foundDateItem) {
            const value = foundDateItem[columnName];
            if (typeof value === "number") {
                graphData.push(Number(value.toFixed(2)));
            }
        } else {
            graphData.push(0);
        }
    });
    return graphData;
};

export const prepareLineChartDataApprovalsReport = (
    reportData: ReportItem[],
    dateRangeList: string[]
) => {
    //prepare graph data for below columns
    const columnNames = ["entry_variance", "cash_variance"];
    const columnLabels: Record<string, string> = {
        entry_variance: "Entry Variance",
        cash_variance: "Cash Variance",
    };

    const graphData: {
        name: string;
        data: number[];
    }[] = columnNames.map((item) => {
        return {
            name: columnLabels[item],
            data: prepareLineChartDataForEachApprovalsDate(
                reportData[0],
                item,
                dateRangeList
            ),
        };
    });
    return graphData;
};

const prepareLineChartDataForEachApprovalsDate = (
    reportItemData: ReportItem,
    columnName: string,
    dateRangeList: string[]
) => {
    const graphData: number[] = [];
    // prepare data for each date
    dateRangeList.forEach((dateItem) => {
        const foundDateItems = reportItemData.data.filter(
            (obj) => obj.date === dateItem
        );
        //aggegate values for same date
        if (foundDateItems.length > 0) {
            const totalValue = foundDateItems.reduce((acc, curr) => {
                const value = curr[columnName];
                if (typeof value === "number") {
                    return acc + value;
                }
                return acc;
            }, 0);
            graphData.push(Number(totalValue.toFixed(2)));
        } else {
            graphData.push(0);
        }
    });
    return graphData;
};

const prepareLineChartDataForEachLocation = (
    reportItemData: ReportItem,
    columnName: string,
    dateRangeList: string[]
) => {
    const graphData: number[] = [];
    // prepare data for each location
    dateRangeList.forEach((dateItem) => {
        const foundDateItem = reportItemData.data.find(
            (obj) => obj.date === dateItem
        );
        //if date is found then add the value
        if (foundDateItem) {
            const value = foundDateItem[columnName];
            if (typeof value === "number") {
                graphData.push(Number(value.toFixed(2)));
            }
        } else {
            graphData.push(0);
        }
    });
    return graphData;
};

export const preparePosGroupBarGraphData = (datalist: GroupReportItem[]) => {
    // Extract venues for labels
    const labels = datalist[0].data.map(
        (item: GroupReportDynamicDataItem) => item.venue
    );
    const allKeys = getAllUniqueKeys(datalist[0].data);
    // Generate series data for the bar chart
    const series: any[] = allKeys.map((keyName) => {
        return {
            name: keyName,
            data: datalist[0].data.map((item: any) => {
                const value = item[keyName as keyof typeof item];
                return value !== undefined ? parseFloat(value.toFixed(2)) : 0;
            }),
        };
    });

    const output: any = {
        labels,
        graphData: series,
    };
    return output;
};

export const getAllDates = (startDateStr: string, endDateStr: string) => {
    const dates: string[] = [];
    const startDate = dayjs(startDateStr, "DD-MM-YYYY");
    const endDate = dayjs(endDateStr, "DD-MM-YYYY");
    let currentDate = startDate;

    // Loop through the dates until the end date
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
        // Format the date as DD/MM/YY
        const formattedDate = currentDate.format("DD/MM/YYYY");
        dates.push(formattedDate);
        // Move to the next day
        currentDate = currentDate.add(1, "day");
    }
    return dates;
};

export const isMoreThan60Days = (dateRange: {
    start_date: string;
    end_date: string;
}) => {
    const startDate: Dayjs = dayjs(dateRange.start_date, "DD-MM-YYYY");
    const endDate: Dayjs = dayjs(dateRange.end_date, "DD-MM-YYYY");
    // Calculate the difference in days between the two dates
    const differenceInDays: number = Math.abs(endDate.diff(startDate, "days"));
    // Check if the difference is greater than or equal to 60 days
    return differenceInDays >= 60;
};

export const isMoreThan7Days = (dateRange: {
    start_date: string;
    end_date: string;
}) => {
    const startDate: Dayjs = dayjs(dateRange.start_date, "DD-MM-YYYY");
    const endDate: Dayjs = dayjs(dateRange.end_date, "DD-MM-YYYY");
    // Calculate the difference in days between the two dates
    const differenceInDays: number = Math.abs(endDate.diff(startDate, "days"));
    // Check if the difference is greater than or equal to 7 days
    return differenceInDays >= 7;
};

export const checkDateRange = (
    dateRange: {
        start_date: string;
        end_date: string;
    },
    numOfDays: number
) => {
    const startDate: Dayjs = dayjs(dateRange.start_date, "DD-MM-YYYY");
    const endDate: Dayjs = dayjs(dateRange.end_date, "DD-MM-YYYY");
    // Calculate the difference in days between the two dates
    const differenceInDays: number = Math.abs(endDate.diff(startDate, "days"));
    // Check if the difference is greater than or equal to 7 days
    return differenceInDays >= numOfDays;
};

export const checkZeroValues = (shiftData: ShiftReportMetricsItem[]): boolean => {
    return shiftData.every((item) => item.Amount === 0);
};

export const sortDataByDate = (
    reportData: ReportDynamicDataItem[]
): ReportDynamicDataItem[] => {
    return reportData
        .map((item, index) => ({
            ...item,
            key: `${item.date}-${index}`,
        }))
        .sort((a: ReportDynamicDataItem, b: ReportDynamicDataItem) => {
            const dateA = dayjs(a.date, "DD/MM/YYYY");
            const dateB = dayjs(b.date, "DD/MM/YYYY");
            return dateA.diff(dateB);
        });
};

export const downloadPdfReport = async (
    reportName: string,
    elementId: string,
    venueName: string,
    date: { start_date: string; end_date: string },
    group?: boolean
) => {
    //hide few elements before downloading
    const elementsToHide = document.querySelectorAll(".hide-for-pdf");
    elementsToHide.forEach((el) => ((el as HTMLElement).style.display = "none"));
    //handle textarea with multiple lines being cutoff
    const originalTextareas: HTMLTextAreaElement[] = [];
    const textareas = document.querySelectorAll("textarea");
    textareas.forEach((textarea) => {
        originalTextareas.push(textarea as HTMLTextAreaElement);
    });
    textareas.forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = textarea.value;
        div.style.cssText = `
        width: ${textarea.clientWidth}px;
        min-height: ${textarea.clientHeight}px;
        border: 1px solid #d9d9d9;
        padding: 4px 11px;
        border-radius: 6px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        line-height: 1.5;
        padding-bottom: 12px; 
    `;
        textarea.parentNode?.replaceChild(div, textarea);
    });
    //prepare content to download
    const element = document.getElementById(elementId) as HTMLElement | null;

    if (!element) {
        console.error(`Element with ID "${elementId}" not found.`);
        return;
    }

    try {
        const canvas = await html2canvas(element, {
            scale: 1,
        });
        const imgData = canvas.toDataURL("image/jpeg");
        const margin = 10;
        const pdfWidth = 210;
        const contentTopMargin = 50;
        const footerMargin = 10;
        const imgWidth = pdfWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pdfHeight = imgHeight + contentTopMargin + footerMargin + 10;
        const isLandscape = pdfHeight < pdfWidth;

        //add pdf page
        const pdf = new jsPDF({
            orientation: isLandscape ? "landscape" : "portrait",
            unit: "mm",
            format: [pdfWidth, pdfHeight],
            compress: true,
        });
        pdf.setFillColor(245, 245, 245);
        pdf.rect(
            0,
            0,
            pdf.internal.pageSize.getWidth(),
            pdf.internal.pageSize.getHeight(),
            "F"
        );
        // Adding logo image
        const logoImg = new Image();
        logoImg.src = "/android-chrome-192x192.png";
        await new Promise((resolve) => {
            logoImg.onload = function () {
                const logoWidth = 15;
                const logoMargin = 5;
                const logoHeight = (logoImg.height * logoWidth) / logoImg.width;
                pdf.addImage(
                    logoImg,
                    "PNG",
                    margin,
                    logoMargin,
                    logoWidth,
                    logoHeight
                );
                resolve(null);
            };
        });
        // Adding titles and text
        const reportType = group ? "Group" : "Venue";
        pdf.setFontSize(16);
        pdf.text(reportName + " Report", margin, 30);
        pdf.setFontSize(10);
        pdf.text(`${reportType} - ${venueName}`, margin, 40);
        pdf.text(`Date Range - ${date.start_date} to ${date.end_date}`, margin, 45);
        // Add report image
        pdf.addImage(
            imgData,
            "JPEG",
            margin,
            contentTopMargin,
            imgWidth,
            imgHeight,
            "",
            "FAST"
        );
        // Adding footer text
        const footerText = `Quantaco ©${new Date().getFullYear()} All rights reserved.`;
        const textWidth = pdf.getTextWidth(footerText);
        const footerTextX = (pdfWidth - textWidth) / 2;
        const footerTextY = imgHeight + contentTopMargin + footerMargin;
        pdf.setFontSize(8);
        pdf.text(footerText, footerTextX, footerTextY);
        //download pdf file
        const fileName = `${reportName}_Report_${venueName}_${date.start_date}_${date.end_date}.pdf`;
        pdf.save(fileName);
        //restore the hidden elements
        elementsToHide.forEach((el) => ((el as HTMLElement).style.display = ""));
        // Restore the textareas from divs
        textareas.forEach((textarea, index) => {
            const div = textarea.parentElement?.querySelector("div");
            if (div) {
                const restoredTextarea = originalTextareas[index];
                restoredTextarea.value = div.textContent || "";
                div.parentNode?.replaceChild(restoredTextarea, div);
            }
        });
    } catch (error) {
        console.error("Error generating PDF Report:", error);
    }
};

export const formatNumberWithCommas = (number: number) => {
    return number.toLocaleString();
};

export const downloadCsvReport = (
    reportName: string,
    venueName: string,
    date: { start_date: string; end_date: string },
    csvData: ReportDynamicCSVDataItem[],
    group?: boolean,
    overviewData?: BankableOverviewReportObject
) => {
    try {
        // Capitalize the first letter of each key
        const capitalizedCsvData = csvData.map((item) => {
            const capitalizedItem: { [key: string]: any } = {};
            Object.keys(item).forEach((key) => {
                const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                capitalizedItem[capitalizedKey] = item[key];
            });
            return capitalizedItem;
        });
        const reportType = group ? "Group" : "Venue";
        // Create header rows for metadata
        const headerRows = [
            [`${reportName} Report`],
            [`${reportType} - ${venueName}`],
            [`Date Range -  ${date.start_date} to ${date.end_date}`],
            [],
        ];
        // If overviewData exists, add key numbers to header rows
        if (overviewData && overviewData.key_numbers) {
            // Add each key number and its value as a row
            Object.entries(overviewData.key_numbers).forEach(([key, value]) => {
                headerRows.push([
                    `${capitalizeWords(key.replace(/_/g, " "))}: ${value}`,
                ]);
            });
            headerRows.push([]);
        }
        let csvString = "";
        const headerString = headerRows.map((row) => row.join(",")).join("\n");
        if (capitalizedCsvData.length > 0) {
            // Dynamically extract fields from the totals object in the array
            const fields = Object.keys(
                capitalizedCsvData[capitalizedCsvData.length - 1]
            );
            const opts = { fields };
            //parse csv
            const parser = new Parser(opts);
            const csvContent = parser.parse(capitalizedCsvData);
            // Convert header rows and csvContent to strings
            csvString = [headerString, csvContent].join("\n");
        } else {
            csvString = [headerString, ""].join("\n");
        }
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        const fileName = `${reportName}_Report_${venueName}_${date.start_date}_${date.end_date}.csv`;
        link.href = url;
        link.setAttribute("download", fileName);
        // Append the link to the document and simulate a click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Error generating CSV Report:", error);
    }
};

export const preparePosCsvData = (
    reportData: ReportItem[],
    totals: Record<string, number>
): ReportDynamicCSVDataItem[] => {
    const csvData: ReportDynamicCSVDataItem[] = reportData.flatMap(
        (locationData: ReportItem) => {
            const { parent_location_name, data } = locationData;
            return data.map((entry: any) => {
                const formattedEntry: ReportDynamicCSVDataItem = {
                    date: entry.date,
                    location: parent_location_name,
                };
                Object.keys(entry).forEach((key) => {
                    const value = entry[key];
                    if (typeof value === "number") {
                        formattedEntry[key] = parseFloat(value.toFixed(2));
                    } else {
                        formattedEntry[key] = value;
                    }
                });
                return formattedEntry;
            });
        }
    );
    // Add totals with formatting
    const formattedTotals: ReportDynamicCSVDataItem = {
        date: "Total",
        location: "",
    };
    Object.keys(totals).forEach((key) => {
        formattedTotals[key] = parseFloat(totals[key].toFixed(2));
    });
    csvData.push(formattedTotals);
    return csvData;
};

export const prepareGamingCsvData = (
    reportData: ReportItem[],
    totals: Record<string, number>
): ReportDynamicCSVDataItem[] => {
    const csvData: ReportDynamicCSVDataItem[] = reportData.flatMap(
        (locationData: ReportItem) => {
            const { data } = locationData;
            return data.map((entry: any) => {
                const formattedEntry: ReportDynamicCSVDataItem = {
                    date: entry.date,
                };
                Object.keys(entry).forEach((key) => {
                    const value = entry[key];
                    if (typeof value === "number") {
                        formattedEntry[key] =
                            key === "Return to House"
                                ? `${parseFloat(value.toFixed(2))}%`
                                : parseFloat(value.toFixed(2));
                    } else {
                        formattedEntry[key] = value;
                    }
                });
                return formattedEntry;
            });
        }
    );
    // Add totals with formatting
    const formattedTotals: ReportDynamicCSVDataItem = {
        date: "Total",
    };
    const specialKeys = new Set([
        "Location",
        "Tender",
        "Description",
        "Customer Name",
    ]);
    Object.keys(totals).forEach((key) => {
        if (key === "Return to House") {
            formattedTotals[key] = `${parseFloat(totals[key].toFixed(2))}%`;
        } else if (specialKeys.has(key)) {
            formattedTotals[key] =
                totals[key] === 0 ? "" : parseFloat(totals[key].toFixed(2));
        } else {
            formattedTotals[key] = parseFloat(totals[key].toFixed(2));
        }
    });
    csvData.push(formattedTotals);
    return csvData;
};

export const preparePettyCashCsvData = (
    reportData: ReportItem[],
    totals: Record<string, number>
): ReportDynamicCSVDataItem[] => {
    // mapping for key
    const keyMapping: Record<string, string> = {
        gl_code: "GL Code",
        class_name: "Class",
        petty_cash_description: "Description",
        amount: "Amount",
        gst: "GST",
    };

    const csvData: ReportDynamicCSVDataItem[] = reportData.flatMap(
        (locationData: ReportItem) => {
            const { data } = locationData;
            return data.map((entry: any) => applyKeyMapping(entry, keyMapping));
        }
    );
    //sort data by date
    const sortedCsvData: ReportDynamicCSVDataItem[] = sortCsvData(csvData);
    // Add totals with formatting
    const formattedTotals: ReportDynamicCSVDataItem = {
        date: "Total",
    };
    const specialKeys = new Set(["gl_code", "class_name", "petty_cash_description"]);
    Object.keys(totals).forEach((key) => {
        const mappedKey = keyMapping[key] || key;
        if (specialKeys.has(key)) {
            formattedTotals[mappedKey] =
                totals[key] === 0 ? "" : parseFloat(totals[key].toFixed(2));
        } else {
            formattedTotals[mappedKey] = parseFloat(totals[key].toFixed(2));
        }
    });
    sortedCsvData.push(formattedTotals);
    return sortedCsvData;
};

export const prepareApprovalsCsvData = (
    reportData: ReportItem[],
    totals: Record<string, number>
): ReportDynamicCSVDataItem[] => {
    // mapping for key
    const keyMapping: Record<string, string> = {
        entry_variance: "Entry Variance",
        cash_variance: "Cash Variance",
        accepted_by: "Accepted By",
        approved_by: "Approved By",
        submitted_by: "Submitted By",
        approved_date: "Approved Date",
    };

    const csvData: ReportDynamicCSVDataItem[] = reportData.flatMap(
        (locationData: ReportItem) => {
            const { data } = locationData;
            return data.map((entry: any) => applyKeyMapping(entry, keyMapping));
        }
    );
    //sort data by date
    const sortedCsvData: ReportDynamicCSVDataItem[] = sortCsvData(csvData);
    // Add totals with formatting
    const formattedTotals: ReportDynamicCSVDataItem = {
        date: "Total",
    };
    const specialKeys = new Set(["entry_variance", "cash_variance"]);
    Object.keys(totals).forEach((key) => {
        const mappedKey = keyMapping[key] || key;
        if (!specialKeys.has(key)) {
            formattedTotals[mappedKey] =
                totals[key] === 0 ? "" : parseFloat(totals[key].toFixed(2));
        } else {
            formattedTotals[mappedKey] = parseFloat(totals[key].toFixed(2));
        }
    });
    sortedCsvData.push(formattedTotals);
    return sortedCsvData;
};

export const prepareBankableCsvData = (
    reportData: ReportItem[],
    totals: Record<string, number>
): ReportDynamicCSVDataItem[] => {
    // mapping for key
    const keyMapping: Record<string, string> = {
        till: "Till",
        cash_in: "Cash In",
        cash_out: "Cash Out",
    };

    const csvData: ReportDynamicCSVDataItem[] = reportData.flatMap(
        (locationData: ReportItem) => {
            const { location_type, data } = locationData;
            return data.map((entry: any) =>
                applyKeyMapping(entry, keyMapping, location_type)
            );
        }
    );
    //skip totals
    if (csvData.length < 1) {
        return csvData;
    }
    //sort data by date
    const sortedCsvData: ReportDynamicCSVDataItem[] = sortCsvData(csvData);
    // Add totals with formatting
    const formattedTotals: ReportDynamicCSVDataItem = {
        date: "Total",
    };
    const specialKeys = new Set(["till"]);
    Object.keys(totals).forEach((key) => {
        const mappedKey = keyMapping[key] || key;
        if (specialKeys.has(key)) {
            formattedTotals[mappedKey] =
                totals[key] === 0 ? "" : parseFloat(totals[key].toFixed(2));
        } else {
            formattedTotals[mappedKey] = parseFloat(totals[key].toFixed(2));
        }
    });
    sortedCsvData.push(formattedTotals);
    return sortedCsvData;
};

export const preparePosGroupCsvData = (
    reportData: ReportItem[],
    totals: Record<string, number>,
    sortCheck: boolean
): GroupReportDynamicDataItem[] => {
    const csvData: GroupReportDynamicDataItem[] = reportData.flatMap(
        (locationData: ReportItem) => {
            const { data } = locationData;
            return data.map((entry: any) => {
                const formattedEntry: GroupReportDynamicDataItem = {
                    venue: entry.venue,
                };
                Object.keys(entry).forEach((key) => {
                    const value = entry[key];
                    if (typeof value === "number") {
                        formattedEntry[key] = parseFloat(value.toFixed(2));
                    } else {
                        formattedEntry[key] = value;
                    }
                });
                return formattedEntry;
            });
        }
    );
    // Add totals with formatting
    const formattedTotals: GroupReportDynamicDataItem = {
        venue: "Total",
    };

    const tableKeys = sortCheck ? Object.keys(totals).sort() : Object.keys(totals);

    tableKeys.forEach((key) => {
        formattedTotals[key] = parseFloat(totals[key].toFixed(2));
    });
    csvData.push(formattedTotals);
    return csvData;
};

export const applyKeyMapping = (
    entry: any,
    keyMapping: Record<string, string>,
    locationType?: string
): ReportDynamicCSVDataItem => {
    const formattedEntry: ReportDynamicCSVDataItem = {
        date: entry.date,
    };
    // Add the area key for bankable
    if (locationType) {
        formattedEntry["area"] = locationType;
    }
    Object.keys(entry).forEach((key) => {
        const mappedKey = keyMapping[key] || key;
        const value = entry[key];
        if (typeof value === "number") {
            formattedEntry[mappedKey] = parseFloat(value.toFixed(2));
        } else {
            formattedEntry[mappedKey] = value;
        }
    });

    return formattedEntry;
};

export const prepareGamingGroupCsvData = (
    reportData: ReportItem[],
    totals: Record<string, number>
): GroupReportDynamicDataItem[] => {
    const csvData: GroupReportDynamicDataItem[] = reportData.flatMap(
        (locationData: ReportItem) => {
            const { data } = locationData;
            return data.map((entry: any) => {
                const formattedEntry: GroupReportDynamicDataItem = {
                    venue: entry.venue,
                };
                Object.keys(entry).forEach((key) => {
                    const value = entry[key];
                    if (typeof value === "number") {
                        formattedEntry[key] =
                            key === "Return to House"
                                ? `${parseFloat(value.toFixed(2))}%`
                                : parseFloat(value.toFixed(2));
                    } else {
                        formattedEntry[key] = value;
                    }
                });
                return formattedEntry;
            });
        }
    );
    // Add totals with formatting
    const formattedTotals: GroupReportDynamicDataItem = {
        venue: "Total",
    };

    Object.keys(totals).forEach((key) => {
        formattedTotals[key] =
            key === "Return to House"
                ? `${parseFloat(totals[key].toFixed(2))}%`
                : parseFloat(totals[key].toFixed(2));
    });
    csvData.push(formattedTotals);
    return csvData;
};

export const prepareAustracVenueCsvData = (
    reportData: AustracReportItem[]
): ReportDynamicCSVDataItem[] =>
    reportData.flatMap(({ shift_date, responses }) =>
        responses.map((entry) => ({
            "Shift Date": formatDate(shift_date),
            "Investigation Question": entry.question_text ?? "",
            Comments: entry.comments ?? "",
            Completed: entry.status === 2 ? "Yes" : "No",
            "Completed By": entry.completed_by ?? "",
            "Completed Date": entry.completed_at
                ? formatUTCToLocalTime(entry.completed_at)
                : "",
            "Approved By": entry.approved_by ?? "",
        }))
    );

export const sortCsvData = (
    csvData: ReportDynamicCSVDataItem[]
): ReportDynamicCSVDataItem[] => {
    return csvData.sort((a, b) => {
        const dateA = dayjs(a.date, "DD/MM/YYYY");
        const dateB = dayjs(b.date, "DD/MM/YYYY");
        return dateA.diff(dateB);
    });
};

const capitalizeWords = (str: string): string => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const dynamicColumnWidth = (columns: any): number => {
    return columns.reduce((total: any, col: any) => {
        const width = typeof col.width === "number" ? col.width : 150;
        return total + width;
    }, 0);
};

export const formatDate = (
    date: string | undefined,
    format = "DD/MM/YYYY"
): string => (date ? dayjs(date, "DD-MM-YYYY").format(format) : "");

export const formatUTCToLocalTime = (date: string): string => {
    const utcDate = new Date(date);
    // Format the date in Australia/Sydney timezone
    const localTime = new Intl.DateTimeFormat("en-AU", {
        timeZone: "Australia/Sydney",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }).format(utcDate);
    return localTime;
};
